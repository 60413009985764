import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Region, RegionResponse } from './region.model';
import { Pagination } from 'app/core/api/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class RegionService {

    private _region: BehaviorSubject<Region[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    pagination: Pagination;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for categories
     */
    get factories$(): Observable<Region[]> {
        return this._region.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get list 
     */
    getRegionsInitial(fromAdmin: boolean = false): Observable<RegionResponse> {
        return this.getRegions(null, null, null, null, null, fromAdmin)
    }

    getRegions(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, fromAdmin: boolean = false): Observable<RegionResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;

        let filter = {
            page: page ? ++page : 1,
            ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10,
        }
        if (fromAdmin == true)
            filter['admin'] = 1
        return this._httpClient.get<RegionResponse>(`${BASE_URL}organization/region/`, {
            params: filter
        }).pipe(
            tap((response) => {
                if (response?.data?.length == 0)
                    this.errorMessage = "There are no factories to display!"
                this._region.next(response?.data);
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
            })
        );
    }

    getRegionDetails(categoryId): Observable<Region> {
        return this._httpClient.get<Region>(`${BASE_URL}organization/region/${categoryId}/`)
    }

    deleteRegion(eventId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}region/${eventId}/`).pipe(
            tap((res: any) => {
                this._region.next(this._region.value.filter(event => event.id !== eventId));
            }, err => of([]))
        )
    }

    createRegion(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}organization/region/`, credentials);
    }

    updateRegion(credentials, eventId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}organization/region/${eventId}/`, credentials)
    }

}
